const PremiumProgressive = ({ className }) => {
	return (
		<svg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M53.8994 20.1638C67.9049 23.8151 80.8843 28.2672 84.0186 37.9678C85.6374 42.9794 83.8042 50.8178 81.068 56.8743C75.2441 69.7659 67.4794 76.8237 53.6731 76.4952C48.7384 76.3789 42.1802 75.4017 36.7119 73.3C32.8607 71.8201 29.4532 70.7004 26.4462 68.9127C17.4435 63.5631 14.5941 54.4192 13.4762 44.7067C12.6342 37.3928 13.3181 26.6565 19.2281 21.2389C25.2594 15.7094 35.5067 16.8845 45.7928 18.3936L53.8994 20.1638Z'
				fill='#C4EAFF'
			/>
			<g opacity='0.4'>
				<ellipse cx='51.4389' cy='32.6575' rx='27.5' ry='9' transform='rotate(15 51.4389 32.6575)' fill='#84D0FC' />
				<ellipse
					cx='51.4389'
					cy='32.6575'
					rx='27.5'
					ry='9'
					transform='rotate(15 51.4389 32.6575)'
					fill='#36208F'
					fill-opacity='0.1'
				/>
				<ellipse cx='45.1926' cy='57.9011' rx='27' ry='13' transform='rotate(15 45.1926 57.9011)' fill='#84D0FC' />
				<ellipse
					cx='45.1926'
					cy='57.9011'
					rx='27'
					ry='13'
					transform='rotate(15 45.1926 57.9011)'
					fill='#36208F'
					fill-opacity='0.1'
				/>
				<g style={{ mixBlendMode: 'soft-light' }} opacity='0.6'>
					<path
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M30.7541 71.3437L75.4988 27.3207C74.3771 26.6861 73.1875 26.0896 71.941 25.5255L27.1944 69.5505C28.3238 70.2244 29.5097 70.8041 30.7541 71.3437Z'
						fill='white'
					/>
				</g>
				<g style={{ mixBlendMode: 'soft-light' }} opacity='0.8'>
					<path
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M48.5643 77.1342L85.3048 40.9862C85.2137 40.0416 85.0437 39.1518 84.784 38.336C83.7848 35.1972 81.7767 32.6024 79.0476 30.4046L35.1163 73.6275C35.7396 73.8617 36.3752 74.1013 37.0233 74.3511C40.653 75.7503 44.7612 76.6518 48.5643 77.1342Z'
						fill='white'
					/>
				</g>
			</g>
			<g clip-path='url(#clip0_19943_324)'>
				<path
					d='M83.5831 21.7116L83.7194 16.8357L75.2904 11.4523L71.2452 13.8317L73.1365 24.4694L83.5831 21.7116Z'
					fill='black'
				/>
				<path
					d='M86.5212 21.4764L86.5758 15.8458L76.7336 9.81004L72.1149 12.6416L74.522 24.8796L86.5212 21.4764Z'
					fill='#FF510E'
				/>
				<path d='M81.5819 18.4475L86.5212 21.4766L74.522 24.8797L81.5819 18.4475Z' fill='#CB3800' />
				<path d='M77.0543 15.6707L72.115 12.6416L74.5221 24.8796L77.0543 15.6707Z' fill='#CB3800' />
				<path d='M86.5212 21.4764L86.5758 15.8458L81.7877 18.5736L86.5212 21.4764Z' fill='#FF7E4D' />
				<path d='M76.8483 15.5443L76.6971 9.78747L72.1148 12.6414L76.8483 15.5443Z' fill='#FF7E4D' />
				<path d='M81.7875 18.5736L81.8421 12.943L76.8482 15.5445L81.7875 18.5736Z' fill='#CB3800' />
				<path
					d='M70.5934 17.5169L70.5618 17.5387C70.1946 17.7919 69.7628 17.9351 69.317 17.9515C69.5381 18.3386 69.6438 18.7807 69.6219 19.2259L69.6201 19.2642L69.6516 19.2424C70.0188 18.9892 70.4507 18.846 70.8964 18.8297C70.6754 18.4426 70.5696 18.0004 70.5915 17.5552L70.5934 17.5169Z'
					fill='#FF510E'
				/>
				<path
					d='M88.0524 14.8612L88.0208 14.8829C87.6536 15.1362 87.2218 15.2794 86.776 15.2957C86.997 15.6828 87.1028 16.125 87.0809 16.5702L87.0791 16.6084L87.1106 16.5867C87.4778 16.3335 87.9096 16.1903 88.3554 16.1739C88.1344 15.7868 88.0286 15.3447 88.0505 14.8994L88.0524 14.8612Z'
					fill='#B5DEF4'
				/>
			</g>
			<g clip-path='url(#clip1_19943_324)'>
				<path
					d='M34.0994 63.0224L27.0113 58.772L12.061 66.7505L12.216 74.7008L29.4974 80.7411L34.0994 63.0224Z'
					fill='black'
				/>
				<path
					d='M36.1917 58.4879L27.9211 53.7316L10.8338 63.2553L11.1804 72.4282L31.2536 79.0356L36.1917 58.4879Z'
					fill='#FF510E'
				/>
				<path d='M27.6163 63.2673L36.1917 58.4878L31.2536 79.0355L27.6163 63.2673Z' fill='#CB3800' />
				<path d='M19.7557 67.6487L11.1804 72.4282L31.2537 79.0357L19.7557 67.6487Z' fill='#CB3800' />
				<path d='M36.1917 58.4879L27.9211 53.7316L27.9737 63.0683L36.1917 58.4879Z' fill='#FF7E4D' />
				<path d='M19.3982 67.8478L10.7703 63.2906L11.1802 72.4281L19.3982 67.8478Z' fill='#FF7E4D' />
				<path d='M27.9737 63.0685L19.7031 58.3121L19.3984 67.848L27.9737 63.0685Z' fill='#CB3800' />
				<path
					d='M18.8364 79.6311L18.8262 79.6953C18.7079 80.4417 18.379 81.1389 17.8782 81.7049C18.6114 81.8861 19.2775 82.2729 19.7982 82.82L19.843 82.867L19.8531 82.8029C19.9715 82.0565 20.3004 81.3592 20.8012 80.7932C20.0679 80.6121 19.4018 80.2252 18.8811 79.6782L18.8364 79.6311Z'
					fill='#FF510E'
				/>
				<path
					d='M32.1616 51.8421L32.1514 51.9062C32.0331 52.6526 31.7042 53.3499 31.2034 53.9159C31.9366 54.097 32.6027 54.4838 33.1234 55.0309L33.1682 55.0779L33.1783 55.0138C33.2967 54.2674 33.6256 53.5702 34.1264 53.0042C33.3931 52.823 32.727 52.4362 32.2063 51.8891L32.1616 51.8421Z'
					fill='#B5DEF4'
				/>
				<path
					d='M37.4847 72.6768L37.4064 72.7949C36.4955 74.1698 35.2111 75.2562 33.7041 75.9265C34.9959 76.9502 35.9682 78.3215 36.5066 79.8793L36.5529 80.0132L36.6311 79.8951C37.542 78.5202 38.8265 77.4337 40.3334 76.7634C39.0416 75.7398 38.0694 74.3684 37.5309 72.8106L37.4847 72.6768Z'
					fill='#B5DEF4'
				/>
				<path
					fill-rule='evenodd'
					clip-rule='evenodd'
					d='M10.9995 68.3992C11.5262 68.6005 12.038 68.8446 12.5297 69.1302L12.6786 69.2167L12.6568 69.0458C12.4621 67.5152 12.6066 65.9662 13.0732 64.507L10.7703 63.2907L10.9995 68.3992Z'
					fill='black'
				/>
				<path
					d='M8.78395 61.0155L8.80569 61.1865C9.05873 63.1758 8.73879 65.1963 7.8835 67.0103C9.8859 66.927 11.8703 67.4177 13.6032 68.4246L13.7521 68.5111L13.7303 68.3401C13.4773 66.3508 13.7972 64.3303 14.6525 62.5163C12.6501 62.5996 10.6658 62.1089 8.93286 61.102L8.78395 61.0155Z'
					fill='#B5DEF4'
				/>
			</g>
			<ellipse cx='47.5' cy='86' rx='26.5' ry='2' fill='#EFEDEE' />
			<path
				d='M79.6344 71.223L79.6924 71.3189C80.3681 72.4355 81.3484 73.3362 82.5181 73.9151C81.4635 74.6826 80.6503 75.7353 80.174 76.9496L80.1331 77.0539L80.0751 76.958C79.3994 75.8414 78.4191 74.9407 77.2494 74.3618C78.304 73.5943 79.1172 72.5416 79.5934 71.3273L79.6344 71.223Z'
				fill='#FF510E'
			/>
			<defs>
				<clipPath id='clip0_19943_324'>
					<rect
						width='23.0176'
						height='23.0176'
						fill='white'
						transform='matrix(-0.852466 -0.522783 -0.522783 0.852466 93.655 14.0332)'
					/>
				</clipPath>
				<clipPath id='clip1_19943_324'>
					<rect width='39' height='39' fill='white' transform='matrix(-0.966462 0.25681 0.25681 0.966462 37.6919 44)' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default PremiumProgressive;
