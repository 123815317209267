import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Accordian, Flex } from '@components/common';
import { useBFContext } from '@context';
import { CustomLenses, LabelText, NavItemTypography } from '@components';
import variables from '@styles/export.module.scss';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import { BUILD_FLOW_STEPS, LOCALE_CODES } from '@constants';
import { useTranslation } from '@utils/index';
import Prescriptions from '../Prescriptions';
import LensPackages from '../LensPackages';
import styles from './QuickAdd.module.scss';

const getTranslatedTexts = translator => {
	return {
		varifocal: translator('varifocal'),
		blPlusLr: translator('bl+lr'),
		bl: translator('bl'),
		selectYourLenses: translator('select-your-lenses'),
		required: translator('required'),
		chooseYourLensType: translator('choose-your-lens-type'),
		customizeYourLenses: translator('customize-your-lenses'),
		selectAny: translator('select-any'),
	};
};

const QuickAdd = () => {
	const { rxType, lensPackage, selectedLenses, step, setStep, readerRx } = useBFContext();
	const [isLensOpen, setIsLensOpen] = useState(true);
	const [isPackageOpen, setIsPackageOpen] = useState(false);
	const [isCustomizeOpen, setIsCustomizeOpen] = useState(false);
	const customizeStepRef = useRef<HTMLDivElement>(null);
	const { locale } = useRouter();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const localizedRxType = locale === LOCALE_CODES.GB && rxType === RX_TYPE.PROGRESSIVE ? translations.varifocal : rxType;

	const handleLensOnClick = () => {
		setIsLensOpen(!isLensOpen);
		if (isPackageOpen) setIsPackageOpen(false);
		if (isCustomizeOpen) setIsCustomizeOpen(false);
	};

	const handlePackageOnClick = () => {
		setIsPackageOpen(!isPackageOpen);
		if (isLensOpen) setIsLensOpen(false);
		if (isCustomizeOpen) setIsCustomizeOpen(false);
	};

	const handleCustomizeOnClick = () => {
		setIsCustomizeOpen(!isCustomizeOpen);
		if (isLensOpen) setIsLensOpen(false);
		if (isPackageOpen) setIsPackageOpen(false);
		setStep(BUILD_FLOW_STEPS.CUSTOMIZE);
	};

	useEffect(() => {
		if (!!rxType && (rxType !== RX_TYPE.READERS || (rxType === RX_TYPE.READERS && !!readerRx))) {
			handlePackageOnClick();
		}
		if (!!lensPackage) {
			handleCustomizeOnClick();
		}
	}, [lensPackage, rxType, readerRx]);

	const parsedSelectedLenses = selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE)
		? translations.blPlusLr
		: selectedLenses.includes(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT)
			? translations.bl
			: null;

	return (
		<div className={styles.container}>
			{/* Rx */}
			<Accordian
				className={styles['accordian']}
				customRow={styles['row-override']}
				isOpen={isLensOpen}
				onClick={handleLensOnClick}
				iconStyle='caret'
				shouldRemoveBorder
				titleChildren={
					<Flex align='start' fullWidth gap={2}>
						<NavItemTypography shouldKeepTypography>{translations.selectYourLenses}</NavItemTypography>
						<LabelText>-</LabelText>
						<LabelText color={variables.gray3}>{!!rxType ? localizedRxType : translations.required}</LabelText>
					</Flex>
				}
			>
				<Flex column gap={3} className={styles['content']}>
					<Prescriptions />
				</Flex>
			</Accordian>
			{/* Lens Package */}
			<Accordian
				className={styles['accordian']}
				customRow={styles['row-override']}
				isOpen={isPackageOpen}
				onClick={handlePackageOnClick}
				iconStyle='caret'
				shouldRemoveBorder
				titleChildren={
					<Flex align='start' fullWidth gap={2}>
						<NavItemTypography shouldKeepTypography>{translations.chooseYourLensType}</NavItemTypography>
						<LabelText>-</LabelText>
						<LabelText color={variables.gray3}>{!!lensPackage ? lensPackage : translations.required}</LabelText>
					</Flex>
				}
			>
				<Flex column className={styles['content']}>
					<LensPackages />
				</Flex>
			</Accordian>
			{/* Lens Add Ons */}
			<Accordian
				className={styles['accordian']}
				customRow={styles['row-override']}
				isOpen={isCustomizeOpen}
				onClick={handleCustomizeOnClick}
				shouldRemoveBorder
				iconStyle='caret'
				titleChildren={
					<Flex align='start' fullWidth gap={2}>
						<NavItemTypography shouldKeepTypography>{translations.customizeYourLenses}</NavItemTypography>
						<LabelText>-</LabelText>
						<LabelText color={variables.gray3}>
							{!!parsedSelectedLenses ? parsedSelectedLenses : translations.selectAny}
						</LabelText>
					</Flex>
				}
			>
				<Flex column className={styles['content']}>
					<CustomLenses ref={customizeStepRef} />
				</Flex>
			</Accordian>
		</div>
	);
};

export default QuickAdd;
