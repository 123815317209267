/* eslint-disable max-lines */
import { useCallback } from 'react';
import cn from 'classnames';
import { m } from 'framer-motion';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { useRouter } from 'next/router';
import variables from '@styles/export.module.scss';
import {
	Button,
	Flex,
	Lozenge,
	NonRx,
	Paragraph,
	PremiumProgressive,
	Progressive,
	Readers,
	Select,
	SingleVision,
	Title,
	TypographyButton,
} from '@components';
import { formatCurrency } from '@utils/shopify';
import { useBFContext } from '@context';
import {
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	CUSTOMER_JOURNEYS,
	DISCOUNT_RATES,
	LOCALE_CODES,
	LOCALE_DICT,
	READER_STRENGTHS,
} from '@constants';
import { RxOption } from '@ts/index';
import { buildFlowPrescriptionVariants } from '@utils/motions';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import { getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import { getReaderStrengthCopy, PRODUCT_TYPES, useFeatureInLocale, useIsVipMembership, useTranslation } from '@utils/index';
import { calculateDiscount } from '@utils/discount';
import styles from '../PrescriptionStep/PrescriptionStep.module.scss';

const PrescriptionIcons = {
	'single-vision': ({ className }) => <SingleVision className={className} />,
	'non-prescription': ({ className }) => <NonRx className={className} />,
	'readers': ({ className }) => <Readers className={className} />,
	'progressives': ({ className }) => <Progressive className={className} />,
	'premium-progressive': ({ className }) => <PremiumProgressive className={className} />,
};

const getTranslatedTexts = translator => {
	return {
		orderedPreviously: translator('ordered-previously'),
		ourSpecialty: translator('our-specialty'),
		selected: translator('selected'),
		select: translator('select'),
		back: translator('back'),
	};
};

const Prescriptions = () => {
	const {
		setStep,
		setStepChange,
		setRxType,
		setReaderRx,
		readerRx,
		rxType,
		step,
		journey,
		rxOptions,
		variantPrices,
		BaseFrameVariant,
		previousBaseFrames,
		hasPreviousBaseFrames,
		setSelectedLenses,
		setLensPackage,
		isQuickAdd,
	} = useBFContext();
	const isLowerTierProgressive = useFeatureInLocale('is-lower-tier-progressive', LOCALE_CODES.US);
	const { locale } = useRouter();
	const { applyDiscountMembership } = useIsVipMembership(PRODUCT_TYPES.LENS);
	const isLensStep = step === BUILD_FLOW_STEPS.LENS;
	const isPackageStep = step === BUILD_FLOW_STEPS.PACKAGE;
	const isProgressiveStep = step === BUILD_FLOW_STEPS.PROGRESSIVE;
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const isSunLens = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const isNotEyeGlasses = journey !== CUSTOMER_JOURNEYS.EYEGLASSES;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const shouldShowLowerTier = isQuickAdd && step > BUILD_FLOW_STEPS.LENS && isLowerTierProgressive;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const updateReaderStrength = useCallback(strength => {
		const cleanStr: (typeof READER_STRENGTHS)[number] = strength.replace('+', '');
		setReaderRx(cleanStr);
	}, []);

	const handleClick = useCallback(
		(option: RxOption) => {
			if (
				isLowerTierProgressive &&
				isQuickAdd &&
				option.optionValue === RX_TYPE.PROGRESSIVE &&
				step < BUILD_FLOW_STEPS.PROGRESSIVE
			) {
				setStep(BUILD_FLOW_STEPS.PROGRESSIVE);
				return;
			}

			if (isSunLens && option.optionValue === RX_TYPE.PROGRESSIVE && !isQuickAdd && isLowerTierProgressive) {
				setStep(BUILD_FLOW_STEPS.PROGRESSIVE);
			} else if (isNotEyeGlasses && option.optionValue !== RX_TYPE.READERS) {
				setRxType(option.optionValue);
			} else {
				setRxType(option.optionValue);
				if (!isQuickAdd) {
					setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
				}
			}
			if (isQuickAdd) {
				setSelectedLenses([BASE_FRAME_LENS_OPTIONS.CR39]);
				setLensPackage(null);
			}

			if (isProgressiveStep) {
				setRxType(option.optionValue);
			}
		},
		[isPackageStep, isReadersSelected, isQuickAdd, isProgressiveStep, isLowerTierProgressive]
	);

	let filteredRxOptions = rxOptions;
	const progressiveOptions = rxOptions.filter(option => option.id.includes('progressive'));
	if (isPackageStep && isSunLens) {
		filteredRxOptions = rxOptions.filter(option => option.optionValue === rxType);
	} else if (isSunLens && isLowerTierProgressive) {
		filteredRxOptions = rxOptions.filter(option =>
			isLensStep ? option.optionValue !== RX_TYPE.PREMIUM_PROGRESSIVE : option.id.includes('progressive')
		);
	} else {
		filteredRxOptions = rxOptions.filter(option => option.optionValue !== RX_TYPE.PREMIUM_PROGRESSIVE);
	}

	const renderedProgressiveOptions = shouldShowLowerTier ? progressiveOptions : filteredRxOptions;

	const MappedPrescriptions = () => (
		<>
			{renderedProgressiveOptions.map((option: RxOption, index: number) => {
				if (!BaseFrameVariant.data) return null;
				const isOptionSelected =
					step === BUILD_FLOW_STEPS.LENS &&
					rxType?.includes(RX_TYPE.PROGRESSIVE) &&
					option?.optionValue?.includes(RX_TYPE.PROGRESSIVE)
						? true
						: rxType === option.optionValue;
				const showReaderStrength = isReadersSelected && isOptionSelected;
				const isProgressiveSelected = rxType === RX_TYPE.PROGRESSIVE || rxType === RX_TYPE.PREMIUM_PROGRESSIVE;
				const variantPriceDiff = getVariantPriceDiff({
					lookup: variantPrices,
					current: BaseFrameVariant,
					rxType: option.optionValue,
					sunLensException: isSunLens,
					locale,
					currencyCode: LOCALE_DICT[locale].currencyCode,
				});
				const discountPercent = applyDiscountMembership ? DISCOUNT_RATES.PERCENT_10 : 0;
				const additionalPrice = isOptionSelected && !isSunLens ? variantPriceDiff.without : variantPriceDiff.with;
				const discountedPrice = calculateDiscount(discountPercent, additionalPrice.amount);

				const priceCopy = `+${formatCurrency({ ...additionalPrice, amount: discountedPrice }, showCurr)}`;
				const classes = cn(styles['rx-list-item'], {
					[styles['hover-disabled']]: isPackageStep,
					[styles['is-readers']]: isReadersSelected,
					[styles['extra-z-index']]: isReadersSelected && option.optionValue === RX_TYPE.READERS,
				});
				const isPreviouslyOrdered = hasPreviousBaseFrames && previousBaseFrames[0].rxType === option.optionValue;

				let animate = 'initial';
				let initial = 'initial';
				if (isPackageStep) {
					animate = (isOptionSelected && (isReadersSelected || !isSunLens)) || isQuickAdd ? 'active' : 'inactive';
					initial = isOptionSelected || isQuickAdd ? 'active' : 'inactive';
				}

				return (
					<m.div
						key={option.id}
						id={option.id}
						className={classes}
						initial={initial}
						data-prescription-type={option.copy}
						animate={animate}
						variants={buildFlowPrescriptionVariants(index, isQuickAdd)}
						transition={{ type: 'spring', stiffness: 256, damping: 24, duration: 0.25, mass: 1 }} // MODIFIED
						custom={index}
						onClick={() => handleClick(option)}
					>
						<Flex fullHeight fullWidth>
							{PrescriptionIcons[option.id]({ className: styles.rxIcon })}
							<Flex
								align='center'
								gap={2}
								fullWidth
								style={{ flexGrow: 1, backgroundColor: 'transparent', padding: '1.2rem' }}
							>
								<Flex column gap={1} align='start' style={{ flexGrow: 1 }}>
									<Flex justify='between' fullWidth>
										<Title>{option.copy}</Title>
										{(!isLowerTierProgressive || isProgressiveStep) && (
											<Paragraph className={styles['price']}>{priceCopy}</Paragraph>
										)}
									</Flex>
									<Flex align='center' gap={2} position={'relative'} fullWidth>
										{isPreviouslyOrdered && (
											<Lozenge
												text={translations.orderedPreviously}
												shape='square'
												color={variables.gray1}
												backgroundColor={variables.blue1}
											/>
										)}
										{option.optionValue === RX_TYPE.PROGRESSIVE && !isProgressiveSelected && (
											<Lozenge
												text={translations.ourSpecialty}
												shape='square'
												color={variables.green2}
												backgroundColor={variables.greenLight}
											/>
										)}
										{isOptionSelected ? (
											<Lozenge
												text={translations.selected}
												shape='square'
												color={variables.green2}
												backgroundColor={variables.greenLight}
											/>
										) : null}
									</Flex>
									{(!isPackageStep || isQuickAdd) && (
										<Paragraph style={{ color: variables.gray4 }}>{option.description}</Paragraph>
									)}
									{isPackageStep && !isSunLens && (
										<Paragraph className={styles.selectedText}>{priceCopy}</Paragraph>
									)}
								</Flex>
							</Flex>
						</Flex>
						{showReaderStrength && (
							<div className={styles['readers']} onClick={e => e.stopPropagation()}>
								<Flex justify='between' align='center'>
									<TypographyButton small>{getReaderStrengthCopy(locale)}</TypographyButton>
									<Select
										buttonProps={{ size: 'small' }}
										values={READER_STRENGTHS.map(rs => `+${rs}`)}
										handler={updateReaderStrength}
										placeholder={readerRx ? `+${readerRx}` : translations.select}
									/>
								</Flex>
							</div>
						)}
					</m.div>
				);
			})}
			{shouldShowLowerTier ? (
				<Button
					color='white'
					onClick={() => {
						setStep(BUILD_FLOW_STEPS.LENS);
					}}
					withChevron
					chevronPosition='front'
					chevronDirection='left'
					label={translations.back}
					padding={'0'}
					data-remove-effects={true}
					data-lower-tier-back-button={true}
					style={{ background: 'transparent', border: 0, width: '6rem' }}
				/>
			) : null}
		</>
	);

	return (
		<RadioGroupPrimitive.Root asChild value={rxType}>
			<MappedPrescriptions />
		</RadioGroupPrimitive.Root>
	);
};

export default Prescriptions;
