import { Button, Divider, Flex, Star } from '@components';
import variables from '@styles/export.module.scss';
import { YotpoReviews } from '@ts/yotpo/rewards';
import styles from './YotpoStars.module.scss';
import { useTranslation } from '@utils/index';

const starColorHelper = (score: number, index: number): string => {
	if (score > index + 0.25) return variables.orange1;
	if (index <= score && score <= index + 0.25) return 'transparent';
	return 'transparent';
};

const getTranslatedTexts = translator => {
	return {
		ratingScore: (averageScore: string) => translator('rating-score', { averageScore }),
		reviewsTotal: (totalReviews: number) => translator('reviews-total', { totalReviews }),
		reviewTotalNone: translator('review-total-none'),
	};
};

const YotpoStars = ({ yotpo }: { yotpo: YotpoReviews }) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	if (!yotpo) return;
	const { bottomline } = yotpo;
	const totalReview = bottomline.total_reviews;

	const handleClickScroll = () => {
		const element = document.getElementById('yotpo-reviews');
		if (element) element.scrollIntoView({ behavior: 'smooth' });
	};

	const stars = () => {
		const score = bottomline.average_score || 0;

		return Array(5)
			.fill(0)
			.map((_, i) => <Star color={starColorHelper(score, i)} stroke={variables.orange1} key={i} />);
	};

	return (
		<Flex
			align='center'
			className={styles.container}
			onClick={handleClickScroll}
			style={{ cursor: 'pointer' }}
			data-yotpo-review
		>
			<Flex gap={3} title={translations.ratingScore(bottomline.average_score.toFixed(2))}>
				{stars()}
			</Flex>
			<Divider style={{ height: '1.6rem' }} color={variables.gray3} />
			<Button linkStyle size='small' style={{ padding: 0, color: totalReview ? variables.blue2 : variables.gray3 }}>
				{totalReview ? translations.reviewsTotal(totalReview) : translations.reviewTotalNone}
			</Button>
		</Flex>
	);
};

export default YotpoStars;
