import { forwardRef, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import variables from '@styles/export.module.scss';
import {
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	getBfLensesPackageStepTitle,
	getLensPackagesCopy,
	LENSES_PACKAGES,
	LOCALE_CODES,
	LOCALE_DICT,
} from '@constants';
import { Flex, Lozenge, Paragraph, RadioGroup, Title } from '@components';
import { getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import { formatCurrency } from '@utils/shopify';
import { useBFContext } from '@context';
import { DISCOUNT_RATES, PRODUCT_TYPES, useFeatureInLocale, useIsVipMembership } from '@utils/index';
import { calculateDiscount } from '@utils/discount';
import { useTranslation } from '@utils/hooks';
import styles from './LensPackages.module.scss';

const getTranslatedTexts = translator => {
	return {
		// zz: (xx: string) => translator('yy', { xx }),
		orderedPreviously: translator('ordered-previously'),
	};
};

const LensPackages = forwardRef<HTMLDivElement, { callback?: () => void }>(({ callback }, ref) => {
	const { locale } = useRouter();
	const {
		lensPackage,
		step,
		setStep,
		rxType,
		rxOptions,
		variantPrices,
		BaseFrameVariant,
		setLensPackage,
		setSelectedLenses,
		selectedLenses,
		setStepChange,
		handleCustomLens,
		previousBaseFrames,
		hasPreviousBaseFrames,
		isQuickAdd,
		readerRx,
		materialOptions,
	} = useBFContext();
	const isLowerTierProgressive = useFeatureInLocale('is-lower-tier-progressive', LOCALE_CODES.US);
	const { applyDiscountMembership } = useIsVipMembership(PRODUCT_TYPES.LENS);
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const currentLensOptions = rxOptions.find(option => option.optionValue === rxType)?.lenses ?? [];
	const premiumLens = currentLensOptions.find(copy => copy.optionValue === BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS);
	const lensPackageOptions = !premiumLens
		? Object.values(LENSES_PACKAGES).filter(lensPack => lensPack !== LENSES_PACKAGES.PREMIUM)
		: Object.values(LENSES_PACKAGES);
	const basicLensPackage = lensPackageOptions.filter(lenspack => lenspack === LENSES_PACKAGES.STANDARD);
	const lensPackagesToDisplay = !rxType
		? Object.values(LENSES_PACKAGES)
		: isReadersSelected
			? basicLensPackage
			: lensPackageOptions;
	const { query } = useRouter();
	const isEditMode = Boolean(query?.edit);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const LENS_PACKAGES_COPY = getLensPackagesCopy(locale);
	const isBfAltCopy = useFeatureIsOn('is-bf-alt-copy');
	const contentfulOrderLensPackages = useMemo(() => {
		if (isBfAltCopy) {
			return materialOptions
				.map(mat => mat.type)
				.filter(mat => lensPackagesToDisplay.includes(mat as LENSES_PACKAGES)) as LENSES_PACKAGES[];
		}
		return lensPackagesToDisplay;
	}, [lensPackagesToDisplay, materialOptions, isBfAltCopy]);

	useEffect(() => {
		if (isReadersSelected && isEditMode && readerRx) {
			setLensPackage(LENSES_PACKAGES.STANDARD);
			setSelectedLenses(selectedLenses.filter(lens => lens !== BASE_FRAME_LENS_OPTIONS.CR39));
		}
	}, [isReadersSelected, isEditMode]);

	return (
		<Flex
			column
			style={{
				opacity: !isQuickAdd && !!lensPackage && step === BUILD_FLOW_STEPS.CUSTOMIZE ? 0.5 : 1,
				marginTop: isQuickAdd ? null : '1.6rem',
			}}
			ref={ref}
		>
			{!isQuickAdd && (
				<Title className={styles.title} style={{ fontSize: '1.4rem' }}>
					{isLowerTierProgressive
						? getBfLensesPackageStepTitle(locale).replace('2.', '')
						: getBfLensesPackageStepTitle(locale)}
				</Title>
			)}
			<RadioGroup className={styles.lensPackages} value={lensPackage}>
				<>
					{contentfulOrderLensPackages.map(lenspack => {
						const materialCopy = materialOptions.find(mat => mat.type === lenspack);
						const { with: price } = getVariantPriceDiff({
							lookup: variantPrices,
							current: BaseFrameVariant,
							rxType,
							locale,
							currencyCode: LOCALE_DICT[locale].currencyCode,
							lensPackage: lenspack,
						});
						const discountedPrice = calculateDiscount(
							applyDiscountMembership ? DISCOUNT_RATES.PERCENT_10 : 0,
							price.amount
						);

						const isPreviouslyOrdered = hasPreviousBaseFrames && previousBaseFrames[0].lensPackage === lenspack;

						return (
							<RadioGroup.Option
								key={lenspack}
								callback={() => {
									callback?.();
									if (lenspack !== lensPackage) {
										setLensPackage(lenspack);
										if (lenspack === LENSES_PACKAGES.PREMIUM) handleCustomLens(premiumLens?.optionValue);
										else if (lenspack === LENSES_PACKAGES.BASIC)
											handleCustomLens(BASE_FRAME_LENS_OPTIONS.CR39);
										else setSelectedLenses([]);
									}
									if ((!!lensPackage && step === BUILD_FLOW_STEPS.CUSTOMIZE) || !isLowerTierProgressive) {
										setStep(BUILD_FLOW_STEPS.CUSTOMIZE);
										return;
									}
									!isQuickAdd && setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
								}}
								data-lens-package={lenspack}
								option={lenspack}
								checked={isQuickAdd ? false : isReadersSelected}
								style={
									!isQuickAdd
										? {
												display:
													!lensPackage ||
													(!!lensPackage &&
														(step === BUILD_FLOW_STEPS.PACKAGE || step === BUILD_FLOW_STEPS.LENS)) ||
													(!!lensPackage && lensPackage === lenspack)
														? 'inherit'
														: 'none',
											}
										: null
								}
							>
								<Flex column fullWidth>
									<Flex justify='between' fullWidth>
										<Title>{isBfAltCopy ? materialCopy.altTitle : materialCopy.title}</Title>
										<Paragraph className={styles['lensPrice']}>
											+{formatCurrency({ ...price, amount: discountedPrice }, showCurr)}
										</Paragraph>
									</Flex>
									{isPreviouslyOrdered && (
										<Lozenge
											text={translations.orderedPreviously}
											shape='square'
											color={variables.gray1}
											backgroundColor={variables.blue1}
										/>
									)}
									{LENS_PACKAGES_COPY[lenspack].lozenge && (
										<Lozenge
											text={LENS_PACKAGES_COPY[lenspack].lozenge}
											shape='square'
											color={variables.gray4}
											backgroundColor={variables.gray2}
										/>
									)}
									<Paragraph className={styles.lensCopy}>
										{isBfAltCopy ? materialCopy.altDescription : materialCopy.description}
									</Paragraph>
								</Flex>
							</RadioGroup.Option>
						);
					})}
				</>
			</RadioGroup>
		</Flex>
	);
});

LensPackages.displayName = 'LensPackages';

export default LensPackages;
